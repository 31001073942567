// Generated by Framer (716dd6f)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, Link, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["m7VynreEK"];

const variantClassNames = {m7VynreEK: "framer-v-1ti3zb"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

const BASE62 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz"

function useRandomID(){const ref = React.useRef(null);
if (ref.current === null) {
ref.current = Array(5).fill(0).map(() => BASE62[Math.floor(Math.random() * BASE62.length)]).join("");
}
return ref.current;}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; link?: string }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style: externalStyle = {}, className, width, height, layoutId, variant: outerVariant = "m7VynreEK", link: ATFRj41zv, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "m7VynreEK", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = useRandomID()

const { pointerEvents, ...style } = externalStyle

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div data-framer-generated initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-Uajww", classNames)} style={{display: "contents", pointerEvents: pointerEvents ?? undefined}}>
<Link href={ATFRj41zv}><Image {...restProps} as={"a"} background={{alt: "sparetime-intro-sona-design", fit: "fit", intrinsicHeight: 490, intrinsicWidth: 620, pixelHeight: 490, pixelWidth: 620, src: new URL("assets/1trKYQVdn1tTOJNf2UWBQL2s.jpeg", import.meta.url).href, srcSet: `${new URL("assets/512/1trKYQVdn1tTOJNf2UWBQL2s.jpeg", import.meta.url).href} 512w, ${new URL("assets/1trKYQVdn1tTOJNf2UWBQL2s.jpeg", import.meta.url).href} 620w`}} className={`${cx("framer-1ti3zb", className)} framer-1xc3sjs`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"m7VynreEK"} ref={ref} style={{filter: "grayscale(1)", WebkitFilter: "grayscale(1)", ...style}} transition={transition}/></Link>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-Uajww [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-Uajww * { box-sizing: border-box; }", ".framer-Uajww .framer-1xc3sjs { display: block; }", ".framer-Uajww .framer-1ti3zb { height: 887px; overflow: hidden; position: relative; text-decoration: none; width: 1140px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 886.5
 * @framerIntrinsicWidth 1140
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"ATFRj41zv":"link"}
 */
const FramersoK6qWMrl: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default FramersoK6qWMrl;

FramersoK6qWMrl.displayName = "Spare time";

FramersoK6qWMrl.defaultProps = {height: 886.5, width: 1140};

addPropertyControls(FramersoK6qWMrl, {ATFRj41zv: {title: "Link", type: ControlType.Link}} as any)

addFonts(FramersoK6qWMrl, [])